<template src="./PrivacyPolicies.html"></template>

<script>
export default {
  name:'privacy-policies',
  methods: {
    redirectTo(route) {
      this.$router.push({path: `${route}`})
    }
  }
}
</script>

<style lang="scss" scoped src="./PrivacyPolicies.scss">

</style>